import { createContext, ReactNode } from 'react';

export interface ICheckoutContext {
    placeOrderButton: ReactNode | null;
    cartHasBalance: boolean;
    mailChimp: ReactNode | null;
    termsAndConditions: ReactNode | null;
}

export const CheckoutContext = createContext<ICheckoutContext>({
    placeOrderButton: null,
    cartHasBalance: true,
    mailChimp: null,
    termsAndConditions: null
});

const { Consumer: CheckoutConsumer, Provider: CheckoutProvider } = CheckoutContext;

export { CheckoutConsumer, CheckoutProvider };
