import { ICookieContext } from '@msdyn365-commerce/core-internal';
import { IGiftCardExtend } from '@msdyn365-commerce/global-state';

export interface ILoyaltyCookie {
    loyaltyAmt: number;
    savedDate: Date;
    cartId: string;
}
export interface IGiftCookie {
    giftCards: readonly IGiftCardExtend[] | undefined;
    savedDate: Date;
    cartId: string;
}

export interface IGiftCardsData {
    // giftCardAmt: number;
    giftCards?: readonly IGiftCardExtend[] | undefined;
}

const LOYALTY_COOKIE_NAME = 'loyalty-cookie';
const GC_COOKIE_NAME = 'gc-cookie';

export const setLoyaltyCookie = (cookies: ICookieContext, cookieExpirationTimeInMinutes: number, loyaltyAmount: number, cartId: string) => {
    const cookieExpiryTime = new Date();
    cookieExpiryTime.setMinutes(cookieExpiryTime.getMinutes() + cookieExpirationTimeInMinutes);
    cookies.set<ILoyaltyCookie>(LOYALTY_COOKIE_NAME, { loyaltyAmt: loyaltyAmount, savedDate: cookieExpiryTime, cartId });
};

export const setGiftCardCookie = (
    cookies: ICookieContext,
    cookieExpirationTimeInMinutes: number,
    giftCards: readonly IGiftCardExtend[],
    cartId: string
) => {
    const cookieExpiryTime = new Date();
    cookieExpiryTime.setMinutes(cookieExpiryTime.getMinutes() + cookieExpirationTimeInMinutes);
    cookies.set<IGiftCookie>(GC_COOKIE_NAME, { giftCards: giftCards, savedDate: cookieExpiryTime, cartId });
};

export const getAppliedLoyaltyPoints = (cookies: ICookieContext, cartId: string) => {
    let amtToReturn = 0;
    const loyaltyCookie = cookies.get<ILoyaltyCookie>(LOYALTY_COOKIE_NAME);
    if (loyaltyCookie.value) {
        const loyaltyAmountSaved = loyaltyCookie.value.loyaltyAmt;
        const cookieExpirationTime = new Date(loyaltyCookie.value.savedDate);
        const timeNow = new Date();
        if (cookieExpirationTime > timeNow && loyaltyAmountSaved > 0 && cartId === loyaltyCookie.value.cartId) {
            amtToReturn = loyaltyAmountSaved;
        }
        cookies.remove(LOYALTY_COOKIE_NAME);
    }
    return amtToReturn;
};

export const getAppliedGiftCards = (cookies: ICookieContext, cartId: string) => {
    let giftCardsToReturn: readonly IGiftCardExtend[] | undefined;
    const giftCookie = cookies.get<IGiftCookie>(GC_COOKIE_NAME);
    if (giftCookie.value) {
        const savedGiftCards = giftCookie.value.giftCards;
        const cookieExpirationTime = new Date(giftCookie.value.savedDate);
        const timeNow = new Date();
        if (cookieExpirationTime > timeNow && savedGiftCards && savedGiftCards.length > 0 && cartId === giftCookie.value.cartId) {
            giftCardsToReturn = savedGiftCards;
        }
        cookies.remove(GC_COOKIE_NAME);
    }

    const gcData = <IGiftCardsData>{ giftCards: giftCardsToReturn };
    return gcData;
};
